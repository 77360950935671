<script>
import { ref } from 'vue'
import { useStore, mapGetters } from 'vuex'
import ComOrg from './organization.vue'
export default {
    name: 'layout-base-header',
    computed:{
        ...mapGetters('user', ['userBase'])
    },
    components: { ComOrg },

    setup(){
        let store = useStore()
        return {}
    }
}
</script>
<template>

    <q-header elevated class="bg-primary text-white" height-hint="64">

        <q-toolbar class="EDI__toolbar">
            <q-btn flat dense round aria-label="主菜单" icon="fas fa-bars" class="q-mr-sm"
            @click="$store.commit('page/change-left-menu-status')"></q-btn>
            <q-toolbar-title v-if="$q.screen.gt.xs" shrink class="row items-center no-wrap cursor-pointer" @click="$router.push({name:'home'})">

                <img src="/static/frontimgs/logo-white.png">
                <span class="q-ml-sm text-weight-bolder">eMIS</span>
               <!-- <img src="~@/assets/images/xi.png" class="bg-primary text-white" />
                <span class="q-ml-sm text-weight-bolder">西门子电子订单</span> -->
                

            </q-toolbar-title>
            <com-org />
            <q-space />
            <div class="q-gutter-sm row items-center no-wrap">
                <q-btn round dense flat  icon="fas fa-bell">
                    <q-badge color="red" text-color="white" floating>
                        2
                    </q-badge>
                    <q-tooltip>消息</q-tooltip>
                </q-btn>
                <q-btn round flat>
                    <q-avatar size="26px">
                        <q-img :src="userBase[0].user && userBase[0].user.avatar"/>
                    </q-avatar>
                    <q-tooltip>用户信息</q-tooltip>
                    <q-menu>                        
                        <div class="row no-wrap q-pa-md">
                            <div class="column items-center" style="min-width: 120px;">
                                <q-avatar size="72px">
                                <q-img :src="userBase[0].user && userBase[0].user.avatar" style="width: 30px;"/>
                                </q-avatar> 
                                <div class="text-subtitle1 q-mt-xs" v-if="userBase[0].user.company">
                                    <span class="text-grey"></span>{{ userBase[0].user.company }}</div>
                                <div class="text-subtitle1 q-mt-xs">
                                    <span class="text-grey"></span>
                                    {{ userBase[0].user.username }}</div>
                                <!-- <span class="text-grey">最近登录：{{ userBase.last_login }}</span> -->
                                <q-btn class="q-mt-sm" color="primary" label="退出登录" push size="sm" v-close-popup 
                                icon="fas fa-sign-out-alt" @click="$store.commit('user/logout')" />          
                            </div>
                        </div>
                    </q-menu>
                </q-btn>
            </div>
        </q-toolbar>
    </q-header>
</template>
<style lang="sass">
.EDI
  &__toolbar         
    img
        width: 89px
    .q-btn    
        img
            width: 30px
        
</style>