<script>
import { ref } from 'vue'
import { useStore, mapGetters } from 'vuex'
import MenuMain from './menu/menu.vue'
export default {
    name: 'layout-base-left',
    components: { MenuMain },
    computed:{
        ...mapGetters('page', ['getBaseMenu'])
    },
    setup(){
        
        return {
            thumbStyle: ref({
                right: '2px',
                borderRadius: '5px',
                backgroundColor: '#027be3',
                width: '0px',
                opacity: 0.75
            }),
            miniState: ref(true)

        }
    },

}
</script>
<template>
    <q-drawer v-model="getBaseMenu.status" show-if-above bordered class="bg-grey-3"
    :width="240"
    :breakpoint="500"
    :mini="miniState"
    mini-to-overlay
    
    @mouseover="miniState = false"
    @mouseout="miniState = true"
    >
        <q-scroll-area class="fit" :thumb-style="thumbStyle">
            <q-list padding class="text-grey-8">
                <div class="EDI__drawer-item" v-for="(menu, index) in getBaseMenu.menus" :key="index">
                    <menu-main :menu-list="menu" :init-level="miniState?0:0.4" :style="miniState?'margin-left:-12px':''"/>
                    <q-separator inset class="q-my-sm" v-if="getBaseMenu.menus.length-1>index"/>
                </div>               
                
            </q-list>
        
            <div class="q-mt-lg q-mb-sm absolute-bottom text-center left-drawer-copyright" v-if="!miniState">                        
                    <div class="copy-right text-grey-5">Copyright ©2022 FWCT Software Co., Ltd.</div>
                </div>
            
        </q-scroll-area>
    </q-drawer>
</template>
<style lang="sass">
.left-drawer-copyright
    font-size: 8px
</style>