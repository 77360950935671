<script>

import { mapGetters } from "vuex";
export default {
  name: "page-sticky",

  data() {
    return {
      currentPath: "main",
    };
  },
  computed: {
    ...mapGetters("page", ["getStickyTabs"]),
  },
  created() {
    this.currentPath = this.$route.name;
  },
  methods: {
    routeTo(name) {
      console.log(name, "name///");
      this.$router.push({ name: name });
    },
    removeTab(index) {
      let obj = this.getStickyTabs[index];
      //判断是否关闭的是当前页面
      if (obj.name == this.currentPath) {
        //判断是否是最后一个tab
        if (this.getStickyTabs[index + 1]) {
          this.$router.push({ name: this.getStickyTabs[index + 1].name });
        } else {
          this.$router.push({ name: this.getStickyTabs[index - 1].name });
        }
      }
      this.$store.dispatch("page/delete_sticky_tab", obj);
    },
  },
  watch: {
    $route: {
      handler(v) {
        if (v.path != "/") {
          this.$store.dispatch("page/add_sticky_tab", v);
        }
        this.currentPath = v.name;
      },
      deep: true,
    },
  },
};
</script>
<template>
  <q-page-sticky expand position="top"  class="bar-vue">
    <!-- <q-toolbar style="margin: 0 5px;"> -->
      <q-tabs dense shrink inline-label class="text-grey-7" active-bg-color="primary" active-color="white"
        indicator-color="transparent" v-model="currentPath">
        <q-tab v-for="(tab, index) in getStickyTabs" :key="index" :icon="tab.icon" :ripple="false" :label="tab.title"
          :name="tab.name" exact
          :class="{ glossy: true, 'bg-grey-3': tab.name != currentPath, 'q-mr-sm': true, 'sticky-tab': true }"
          @click="routeTo(tab.name)">
          <q-icon class="q-ml-xs" v-if="tab.name !== 'home'"  name="fas fa-times" @click.stop="removeTab(index)">
            <q-tooltip>关闭</q-tooltip>
          </q-icon>
        </q-tab>
      </q-tabs>
    <!-- </q-toolbar> -->
  </q-page-sticky>
</template>
<style lang="scss" scoped>
.bar-vue {
  display: flex;
  justify-content: flex-start;
  // padding-left: 16px;
  background: #fafafa;
  padding: 6px 0 6px 16px;
  align-items: center;
  z-index: 2;
  .sticky-tab {
    min-height: 24px;
    border-radius: 4px;
  }
  .btn-vue {
    margin: 0 16px;
    width: 100%;
  }
}


//tab的label
:v-deep(.q-tab__label) {
  font-size: 12px;
  height: 12px;
  line-height: 12px;
}

//tab的icon
:v-deep(.q-icon){
  font-size: 12px;
  width: 12px;
  height: 12px;
}
//page-bar左右滑动箭头居中
:v-deep(.q-tabs--horizontal .q-tabs__arrow--left){
  top:6px
}
:v-deep(.q-tabs--horizontal .q-tabs__arrow--right){
  top:6px
}

</style>