<!--
 * @Description  : 报价单详情页
 * @Author       : fan fs670267542@163.com
 * @LastEditors  : fan fs670267542@163.com
 * @LastEditTime : 2023-07-21 13:49:12
-->
<template>
  <div>
    <!-- 操作按钮 -->
    <div class="header-vue">
      <div v-for="(item) in btnSet.btns" :key="item.index">
        <operation-tool :button-item="item" @toolClick="toolClick(item)" @submit="submit" />
      </div>
    </div>
    <div class="content-vue">
      <!-- 基本信息 -->
      <q-card class="baseInfo bg-white shadow-2 q-mb-md">
        <q-btn color="primary" rounded round size="sm" flat dense
          :icon="expanded ? 'fas fa-angle-up' : 'fas fa-angle-down'" @click="expanded = !expanded" />
        <q-btn flat color="primary" label="基本信息" />

        <div v-show="expanded">
          <q-separator />
          <q-card-section>
            <base-form :formProps="formSet" />
          </q-card-section>
        </div>

      </q-card>

      <!-- 供应商信息 -->
      <q-card class="campany bg-white shadow-2 q-mb-md">
        <q-btn color="primary" rounded round flat dense size="sm"
          :icon="companyExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down'"
          @click="companyExpanded = !companyExpanded" />
        <q-btn flat color="primary" label="供应商信息" />

        <div v-show="companyExpanded">
          <q-separator />
          <q-card-section>
            <!-- table组件 -->
            <base-table @addRow="addRow" @deleteRow="deleteRow" @handleCount='handleCount' @onFoucs='onFoucs' />
          </q-card-section>
        </div>
      </q-card>
    </div>
  </div>
</template>
<script>
import BaseForm from "@/components/pieces/form/base.vue";
import BaseTable from "@/components/pieces/tables/myDefault.vue";
import OperationTool from "@/components/pieces/operation/button-group.vue";
import { useStore } from "vuex";
import { ref, provide, computed } from "vue";

export default {
  components: {
    OperationTool,
    BaseForm,
    BaseTable,
  },
  setup(props, context) {
    const store = useStore();

    let btnSet = computed(() => store.getters["quotation/DetailBtn"]);
    let formSet = computed(() => store.getters["quotation/DetailForm"]);
    let tableSet = computed(() => store.getters["quotation/DetailTable"]);
    provide("buttonsSet", btnSet.value);
    provide("tableSource", tableSet.value);
    const methods = {
      toolClick(item) {
        console.log(item, "item");
        if (item.sign == "save" || item.sign == "cancel") {
          //清除列表选中的select
          // store.commit("quotation/clearListSelect");
          // store.commit("quotation/clearDetailSelect");
          //todo 调用接口,显示计算列
          store.dispatch("quotation/onSave");
        }
        if (item.sign == "back") {
          context.emit("backList");
          sessionStorage.setItem("currentPage", "list");
        }
      },
      //保存
      submit() {
        store.dispatch("quotation/addOrEdit");
      },
      //新增行
      addRow() {
        store.commit("quotation/addRow");
      },
      //删除行
      deleteRow() {
        //本地假删除,保存时候是真删除
        // store.commit('quotation/deleteRow')
        store.dispatch("quotation/deleteDetailRow");
      },
      //编辑时隐藏计算结果的列
      onFoucs() {
        store.commit("quotation/hiddenColumns");
      },
      //计算
      // handleCount(num,row,col){
      //   console.log(num,row,col,'接收//');
      //   console.log(tableSet.value,'数据源');
      //    console.log(tableSet.value.data[row].tax_list_price,tableSet.value.data[row].tax_rate,'新数据')

      //   // 面价(未税)==列表价(含税)/((100+税率(%)/100))
      //   if(col=='tax_list_price'||col == 'tax_rate'){
      //            tableSet.value.data[row].list_price = Math.round(tableSet.value.data[row].tax_list_price*1/((100+tableSet.value.data[row].tax_rate*1)/100)*100)/100
      //   }
      //   // 实际下浮=100-{[(100-标准下浮)/100*(100-特殊下浮)/100]*100}
      //   if(col=='standard_discount'||col == 'special_discount'){
      //     tableSet.value.data[row].discount = Math.round((100 - (((100-tableSet.value.data[row].standard_discount*1)/100*(100-tableSet.value.data[row].special_discount*1)/100)*100))*100)/100
      //   }
      //   // 实际含税单价==列表价(含税)*(100-实际下浮(%))/100
      //   if(col=='tax_list_price'||col == 'discount'){
      //     tableSet.value.data[row].tax_price =  Math.round((tableSet.value.data[row].tax_list_price*1*(100-tableSet.value.data[row].discount*1)/100)*100)/100
      //   }
      //   // 实际未税单价=实际含税单价/((100+税率(%)/100)
      //   if(col=='tax_price'||col == 'tax_rate'){
      //     tableSet.value.data[row].price = Math.round((tableSet.value.data[row].tax_price*1/((100+tableSet.value.data[row].tax_rate*1)/100))*100)/100
      //   }
      //   // 实际含税金额=实际含税单价*数量
      //   // 含税下浮额=面价(含税)*数量*(100-实际下浮(%))/100
      //   // 实际未税金额=实际未税单价*数量
      //   // 未税下浮额='=面价(未税)*数量*(100-实际下浮(%))/100
      //   // 核算利润='=面价(含税)*数量*(100-实际下浮(%))/100 - 面价(含税)*数量*(100-核算成本折扣(%))/100
      //   // 核算利润率='=核算利润/实际含税金额*100
      // }
    };

    return {
      ...methods,
      tableSet,
      btnSet,
      formSet,
      // 详情数据
      expanded: ref(true),
      companyExpanded: ref(true),
    };
  },
};
</script>
