<script>
import MenuItem from './menu-item.vue'
export default {
    name: 'menu-main',
    props: ['menuList', 'initLevel'],
    components:{ MenuItem }
    
}
</script>
<template>
    <div>
        <div v-for="(item, index) in menuList" :key="index">
            <menu-item :item="item" :init-level="initLevel"/>            
        </div>
        
    </div>    
</template>
