<template>
  <q-page-container>
    <q-page class="EMIS__page">
      <!-- 导航页签 -->
      <page-bar />
      <!-- 内容 -->

      <router-view v-slot="{ Component }" id="scroll-wrap">
        <transition mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </q-page>
  </q-page-container>
</template>
<script>
import PageBar from "./menu/page-bar.vue";
export default {
  name: "emis-content",
  components: {
    PageBar,
  },
};
</script>

<style lang='scss' scoped>
.EMIS {
  &__page{
    padding-top: 36px;
  }
}
</style>


