<script>
import { ref, inject, computed, watch, onMounted  } from 'vue'
import { useStore } from 'vuex'
export default {
    name: 'header-organization',
    setup(){
        let store = useStore()
        // store.dispatch('user/fetch-organization')
        let orgList = computed(()=> store.getters['user/orgList'])
        let orgOptions = computed(()=> {
            let opts = []
            orgList.value.forEach(it=>{
                opts.push({
                    label: it.name,
                    value: it.id
                })
            })
            return opts
        })
        
        let org = ref(null)
        org.value = localStorage.getItem('emisOrganization') ? JSON.parse(localStorage.getItem('emisOrganization')) : null
        watch(()=> org.value, (v)=>{
            if(v){
                localStorage.setItem('emisOrganization', v)
                window.location.reload()
            }            
        })
        return {
            orgOptions, org
        }
    }
}
</script>
<template>
    <div>  
        <q-select  dense v-model="org" :options="orgOptions"
        borderless  :label-slot="false"  map-options emit-value dark :options-dark="false"
        hide-bottom-space transition-show="jump-up"
        transition-hide="jump-up" style="max-width:150px" 
        >
        <template v-slot:selected-item="scope">
            <div class="ellipsis">{{scope.opt.label}}</div>
        </template>
        </q-select>
    </div>
</template>
<style lang="sass">
.ellipsis
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
</style>
