<script>
import { ref,toRef, inject, computed} from 'vue'
export default {
    name: 'com-button-item',
    components: { },
    props: ['buttonItem'],
    setup(props, context){
        let node = toRef(props, 'buttonItem')
        let btnIsCheck = ref(0)
        let buttonsSet = inject("buttonsSet")
        const btnGroupCheck = index => btnIsCheck.value = index
        
        function toolClick(node){
            context.emit('toolClick')
            switch (node.sign) {
                case 'save':
                    context.emit('submit')
                    break;
                case 'add':
                    context.emit('add', node)
                    break;
                case 'edit':
                    context.emit('edit', node)
                    break;
                case 'del':
                    context.emit('del', node)
                    break;
                case 'cancel':
                    context.emit('cancel', node)
                    break;
                case 'exit':
                    context.emit('exit')
                    break;
            
                default:
                    break;
            }
            // if(node.sign == 'save'){
            //     context.emit('submit')
            // } else if (node.sign == 'add') {
            //     context.emit('add')
            // } else if (node.sign === 'edit') {
            //     context.emit('edit', node)
            // } else if (node.sign === 'del') {
            //     context.emit('del', node)
            // }
            
        }
        function btnCheck(){
            context.emit('btnCheck')
        }
        function submit(){
            context.emit('submit')
        }
        function click_submit(){
            if(node.value.append&&node.value.append.click_submit){                
                submit()
            }
        }
        function keyup() {
            if(node.value.append&&node.value.append.enter){                
                submit()
            }
        }
        return { node, buttonsSet, btnIsCheck,btnCheck,toolClick,btnGroupCheck, keyup, submit, click_submit }
    }
}
</script>

<template>
    <div buttons-vue>
        <div class="com-button-item">
        <q-btn :dense="node.dense" :flat="node.flat" :disabled="node.disable" v-if="node.type=='text' || node.type=='separate'"  :key="index" @click="toolClick(node)">{{ node.label }}</q-btn>   
        <q-btn v-if="node.type=='submit'" unelevated :style="{minWidth: node.minWidth}" dense color="primary" :icon="node.variant&&node.variant.icon?node.variant.icon:''"
            :label="node.label?node.label:''" @click="submit()">
        </q-btn>
        <!-- 正圆开关button -->
        <q-btn v-if="node.type=='round'" rounded round size="sm" flat dense :icon="node.variant&&node.variant.icon?node.variant.icon:''" @click="btnClick()">
        </q-btn>
        <!-- 矩形单选button组 -->
        <template v-if="node.type=='square-group'">
            <div class="row q-gutter-sm">
                <div v-for="(item, index) in node.variant.origin" :key="item">
                    <q-btn dense outline :class="[btnIsCheck === index ? 'checkBtn': 'nomalBtn']" @click="btnGroupCheck(index)">
                        {{ node.variant.origin[index] }}
                    </q-btn>
                </div>
            </div>
        </template> 
        <q-btn v-if="node.type=='square'" dense outline color="primary" :label="node.label?node.label:''" @click="toolClick(node)"></q-btn>
        <!-- <q-btn v-for="item in buttonsSet.buttons" :key="item.index" unelevated push  size="sm"       
            :icon="item.icon" :label="item.name" color="primary" @click="toolClick(item)">            
        </q-btn>
         -->
    </div>
    <!-- <com-dialog :dialogProps="dialog_props" v-if="dialog_props"/> -->
</div>
</template>
<style lang="scss" scoped>
.com-button-item ::v-deep {
    font-size: $tableFontSize;
    span {
        cursor: pointer;
    }
    .checkBtn {
        color: $secondary;
    }
    .nomalBtn {
        color: $normal;
    }
  // button
  .q-btn .q-icon {
    font-size: $tableFontSize;
  }
  .q-field--with-bottom {
    padding-bottom: 0;
  }
}
</style>