<script>
import { mapGetters } from "vuex";
import quotationDetail from "@/views/quotation/detail.vue";
export default {
  name: "layout-base-right",
  components: {
    quotationDetail,
  },
  computed: {
    ...mapGetters("page", ["getDetailView"]),
  },
  setup() {},
};
</script>
<template>
  <div class='main-vue'>
    <q-drawer v-model="getDetailView.status" side="right" :width="800" :breakpoint="500" overlay bordered class="drawer">
      <!-- <div class="drawer"> -->
         <!-- 每个页面的详情 -->
      <quotationDetail v-if="getDetailView.page == 'quotation'"/>
      <!-- </div> -->
    </q-drawer>
  </div>

</template>
<style>

  .drawer{
    /* position: absolute;
    height: 100vh;
    background: pink;
    top: 52px;
    right: 0;
    width: 50%;
    z-index: 3; */
    
    box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.5);
    
  }

 
</style>

