<script>
import { ref, nextTick } from "vue";
import BaseHeader from "./base-header.vue";
import BaseLeft from "./base-left.vue";
import BaseRight from "./base-right.vue";
import PageContent from "./content.vue";
import { mapGetters } from "vuex";
export default {
  name: "layout-base",
  components: { BaseHeader, BaseLeft, PageContent,BaseRight },
  computed: {
    ...mapGetters("page", ["getResponse"]),
  },
  provide() {
    return {
      setPageReload: this.pageReload,
    };
  },
  setup() {
    let isRouterAlive = ref(true);
    const myRef = ref(null);
    function pageReload() {
      isRouterAlive.value = false;
      nextTick(() => {
        isRouterAlive.value = true;
      });
    }

    return { isRouterAlive, pageReload };
  },
  watch: {
    getResponse(v1) {
      if (v1) {
        let t = v1.status == "positive" ? 1 : 3000;
        this.$q.notify({
          progress: true,
          timeout: t,
          type: v1.status,
          multiLine: true,
          message:
            v1.status_code == 500 ? "服务器错误" : `${v1.title}:${v1.data}`,
        });
      }
    },
  },
};
// hHh lpR fFf
</script>
<template>
  <q-layout view="hhh lpr fFf" class="bg-grey-1">
    <base-header />
    <base-left />
    <page-content v-if="isRouterAlive" />
    <base-right />
  </q-layout>
</template>
