<script>
import {ref, reactive, toRefs, inject, nextTick} from "vue";
import OperationTool from "@/components/pieces/operation/button-group.vue";

export default {
  name: "piece-base-table",
  props: ["tableTitle"],
  components: {
    OperationTool,
  },
  setup(props, context) {
    const myRef = ref(null);
    const tableRef = ref(null);
    let pasteRow = ref(null); //粘贴行位置
    let pasteCol = ref(null); //粘贴列位置
    let tableSource = inject("tableSource");
    let data = reactive({
      pagination: {
        //每页多少行
        // rowsPerPage:this.tableSource.pagination?(localStorage.getItem('DEFAULT-PAGE-SIZE')?parseInt(localStorage.getItem('DEFAULT-PAGE-SIZE')):10):0,
        //第几页
        // page: this.tableSource.pagination?parseInt(this.tableSource.pagination.page):1,
        rowsPerPage: 10,
        page: 1,
      },
    });
    const methods = {
      handleClick(props) {
        pasteRow.value = props.rowIndex;
        pasteCol.value = props.col.name;
        //排他思想,所有的都置为false,点谁再给谁赋值
        tableSource.data.map((item) => {
          item.rowKey = null;
        });
        props.row.rowKey = props.rowIndex;
        props.row.colKey = props.col.name;
        nextTick(() => {
          myRef.value.focus();
        });
      },

      toolClick(item) {
        if (item.sign == 'add') {
          context.emit("addRow");
        } else if (item.sign == 'delete') {
          context.emit("deleteRow");
        }
      },
      rowClick(e, row) {
        context.emit("rowClick", row);
      },
      //失去焦点,把点位置空,input隐藏
      onBlur() {
        tableSource.data.map((item) => {
          item.rowKey = null;
          // item.colKey = null;
        });
      },
      //粘贴事件
      pasteInfo(e) {
        try {
          var data = null;
          var clipboardData = e.clipboardData;
          //复制过来的内容
          data = clipboardData.getData("Text");
          var rowStrArray = data.split("\n"); //根据换行拆分成数组
          //复制的数据转成数组,存起来
          let rowsInfo = [];
          for (var i = 0; i < rowStrArray.length - 1; i++) {
            var row = [];
            var tdStrArray = rowStrArray[i].split("\t"); //按列拆分
            for (var j = 0; j < tdStrArray.length; j++) {
              row.push(tdStrArray[j]);
            }
            rowsInfo.push(row);
          }
          //复制的数据二维数组转成一维数组
          let list = rowsInfo.reduce((total, currentValue) => {
            return total.concat(currentValue);
          });
          //存储粘贴后的数组
          const arr = [];
          //粘贴的数组 比 要替换的数组短的时候
          tableSource.data.map((item, index) => {
            list.map((ite, idx) => {
              if (index == idx + pasteRow.value) {
                arr.push({...item, [pasteCol.value]: ite});
              }
            });
          });
          //粘贴的数组比  要替换的数组长的时候
          //list3:从粘贴位置开始,后半截数组
          const list3 = tableSource.data.slice(pasteRow.value);
          const list2 = list.slice(list3.length);
          if (list.length > list3.length) {
            //粘贴位置开始 的数组
            list2.map((item, index) => {
              arr.push({
                [pasteCol.value]: item,
                id:
                    (Math.random() + new Date().getTime())
                        .toString(32)
                        .slice(0, 8) + index,
              });
            });
          }
          console.log(arr, "arr000");
          //arr是从当前行往后粘贴完的数组  tableSource是table原本的数组
          arr.forEach((item) => {
            let index = tableSource.data.findIndex((e) => e.id == item.id);
            if (index > -1) {
              tableSource.data[index] = item;
            } else {
              tableSource.data.push(item);
            }
          });
        } catch (err) {
          this.$Message.error({
            content: "请选择复制位置",
          });
        }
      },
      //todo, bug
      // onFoucs() {
      //   context.emit("onFoucs");
      // },

      //表格数值计算
      onChange(e) {
        console.log(e, "ee");
        console.log(pasteRow.value, pasteCol.value, "点位");
        context.emit("handleCount", e, pasteRow.value, pasteCol.value);
      },

      // tab栏切换
      tooglepruducts(event) {
        event.currentTarget.parentElement.parentElement.parentElement.parentElement.nextElementSibling.style.display = 'block'
      }
    };
    return {
      ...toRefs(data),
      tableSource,
      ...methods,
      myRef,
      tableRef,
      pasteRow,
      pasteCol,
    };
  },
};
</script>
<template>
  <div ref="tableRef">
    <div class="table-header" v-if="tableSource.tools">
      <div v-for="(item) in tableSource.tools.btns" :key="item.index">
        <operation-tool :button-item="item" @toolClick="toolClick(item)"/>
      </div>
    </div>
    <q-table :dense="tableSource.dense" square flat color="primary" :columns="tableSource.columns"

             :rows="tableSource.data" :row-key="id" :selection="tableSource.selection"
             :visible-columns="tableSource.visibleColumns"
             :table-style="{height: tableSource.height}" v-model:selected="tableSource.selected"
             v-model:pagination="pagination" :hide-pagination='tableSource.hidePagination'
             :separator="tableSource.separator"
             @row-click='rowClick' @paste.enter.capture.prevent="pasteInfo($event)">
      <template v-slot:top-left v-if="tableSource.tools">
        <!-- 上左插槽的标题 -->
        <div v-if="tableSource.tools.title" style="font-size: 14px;" class="q-ma-xs col-12">
          {{ tableSource.tools.title ? tableSource.tools.title : '' }}
        </div>
        <!-- 上左插槽的操作按钮 -->
        <div class="table-header" v-if="tableSource.tools.btns">
          <template v-for="btn in tableSource.tools.btns" :key="btn.index">
            <q-btn flat dense class="q-mr-sm" @click="btn.methods">{{ btn.label }}</q-btn>
          </template>
        </div>
        <!-- 上左插槽的tabs栏 -->
        <div v-if="tableSource.tools.tabs">
          <q-tabs
              v-model="tableSource.tab"
              dense
              no-caps
              inline-label
              align="justify"
              narrow-indicator
              shrink
              stretch
              class="bg-white text-grey-6"
              active-color="grey-9"
              active-class="active-tab"
              indicator-color="transparent"
          >
            <!-- v-for="tab in tableSource.tools.tabs" :key="tab.name" -->
            <q-tab dense name="pruducts" @click="tooglepruducts($event)" label="列表信息"/>
            <!-- <q-tab dense :name="tab.name" @click="tooglepruducts($event)" :label="tab.label" /> -->
            <!-- <q-tab dense name="others" @click="toogleothers($event)" label="其他信息" /> -->
          </q-tabs>
        </div>
      </template>
      <!-- 上右插槽的操作按钮 -->
      <template v-slot:top-right="props" v-if="tableSource.tools.toolsbar">
        <div v-if="tableSource.tools.toolsbar" class="text-right">
          <q-btn
              rounded
              round
              size="sm"
              flat
              dense
              icon="fas fa-redo"
          >
            <q-tooltip>刷新</q-tooltip>
          </q-btn>
          <q-btn
              rounded
              round
              size="sm"
              flat
              dense
              :icon="props.inFullscreen ? 'fas fa-expand' : 'fas fa-expand-arrows-alt'"
              @click="props.toggleFullscreen"
          >
            <q-tooltip>全屏</q-tooltip>
          </q-btn>

        </div>
</template> -->
<!-- 可编辑的表格 -->
<template v-slot:body-cell="props" v-if="tableSource.edit">
  <q-td :props="props" @click="handleClick(props)">
    <!-- {{props.row.rowKey}}--{{props.row.colKey}} -->
    <span
        v-if="props.row.rowKey != props.rowIndex || props.row.colKey != props.col.name">{{ props.value == 'undefined' ? '' : props.value }}</span>
    <q-input v-if="props.row.rowKey == props.rowIndex &&props.row.colKey == props.col.name"
             v-model="tableSource.data[props.rowIndex][props.col.name]" dense outlined ref='myRef' @blur="onBlur"
             @focus="onFoucs" @change="onChange"/>
  </q-td>
</template>
</q-table>
</div>
</template>

<style lang="scss">

.layout-table {
  // min-height: 400px;
  .q-table {
    border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .q-table__top {
    border-bottom: none;
  }

  .my-special-class {
    min-width: 130px;
    text-align: center;
  }
}

.table-header {
  display: flex;
}


// 表格边框
.q-table::v-deep {
  border: 1px solid rgba(0, 0, 0, 0.12);
  // 表格上方插槽
  // .q-table__top {
  //   padding-left: 16px; 
  //   padding-bottom: 0px; 
  //   border-bottom: none;
  //   .active-tab {
  //     border: 1px solid rgba(0, 0, 0, 0.12);
  //     border-radius: 5% 5% 0 0;
  //     border-bottom: none;
  //   }
  // }
}

:v-deep(.q-tab__content .q-tab__label) {
  font-size: 12px;
}

// 表头居中
.my-special-class {
  text-align: center;
}

// 去除表格上面插槽多出来的1px的线
.q-table--cell-separator .q-table__top {
  border-bottom: none;
}

  // input框高度
:v-deep(.q-field--dense .q-field__control) {
  min-height: $inputHeight;
  height: 24px;
  font-size: $tableFontSize;
}
</style>