<script>
export default {
    name: 'menu-item',
    props: ['item', 'initLevel'],
    computed:{
        currentPath() {   
            return this.$route.path
        },
        setLevel(){
            let level = parseInt((this.initLevel-0.2)/0.3)
            return 'margin-left: ' + level*16 + 'px'
        },       
       
    },
    data(){
        return {
            open: false
        }
    },
    watch:{
        $route:{
            handler(v){
                this.open = v.path.startsWith(this.item.group)
            },
            deep: true
        }
    },
    created(){
        this.open = this.$route.path.startsWith(this.item.group)
    }
}
</script>
<template>
    <div class="EMIS__drawer-item">
        <q-item v-if="!item.children" clickable v-ripple :class="currentPath==item.to?'menu-active':''"
            :to="item.to" >
            <q-item-section avatar :style="setLevel"><q-icon :name="item.icon"/></q-item-section>
            <q-item-section><q-item-label class="text-weight-bold main-menu">{{item.title}}</q-item-label></q-item-section>
        </q-item>
        <q-expansion-item v-else expand-separator :header-inset-level="initLevel" :to="item.path" :icon="item.icon"
        :label="item.title" :caption="item.caption" v-model="open" header-class="text-grey-8 text-weight-bold">
            <div v-for="(it,index) in item.children" :key="index">
                <menu-item :item="it" :init-level="initLevel+0.3"/>
            </div>            
            
        </q-expansion-item>
    </div>
</template>
<style lang="sass" scoped>
.main-menu
    margin-left: 12px
.EMIS
    &__drawer-item
        .menu-expansion
            color: #5f6368
        .q-item 
            line-height: 24px
            border-radius: 24px 0 0 24px
            margin-left: 12px
            
            .q-item__section--avatar
                padding-left: 12px
                .q-icon
                    color: #5f6368
            .q-item__label:
                color: #5f6368
                letter-spacing: .01785714em
                font-size: .875rem
                font-weight: 500
                line-height: 1.25rem
        .menu-active
            background-color: #2196f3
            .q-item__section--avatar
                .q-icon
                    color: #fff
            .q-item__label:not(.q-item__label--caption)
                color: #fff
</style>